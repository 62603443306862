// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCI778hxpP1hwknSaGMM73SwmpyTTlaYUI",
	authDomain: "hedgehog-lock.firebaseapp.com",
	projectId: "hedgehog-lock",
	storageBucket: "hedgehog-lock.appspot.com",
	messagingSenderId: "954616928766",
	appId: "1:954616928766:web:228a82efa088d7b06788af",
	measurementId: "G-V0RKN0ZPGJ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleAuth = new GoogleAuthProvider();
export const db = getFirestore(app);
