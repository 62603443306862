import "./File.css";

import getFileIcon from "../../icons/FileIcons";
import { updateFileRecentInfo } from "../../service/files";
import { getFormattedDate } from "../../util/util";

export default function File({ file }) {
	async function handleFileClick(e) {
		e?.preventDefault();

		await updateFileRecentInfo(file.id);
		window.open(file.fileUrl, "_blank");
	}

	return (
		<div
			className="file__container"
			onClick={handleFileClick}
			key={file.id}
		>
			{getFileIcon(file.fileType)}
			<p>{file.fileName}</p>
			<span>
				Created {getFormattedDate(new Date(file.createdAt.toMillis()))}
			</span>
		</div>
	);
}
