import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../firebase";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
  },
  reducers: {
    addUser: (state, action) => ({ ...state, user: action.payload }),
    removeUser: async (state) => {
      await auth.signOut();
      return { ...state, user: null };
    },
  },
});

export const { addUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
