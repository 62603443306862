import { useEffect, useState } from "react";

import "./Recent.css";

import File from "../../components/file/File";
import { getRecentFiles } from "../../service/files";

export default function Recent() {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		async function fetchFiles() {
			const recentFiles = await getRecentFiles();
			if (recentFiles.length > 0) {
				setFiles(recentFiles);
			}
		}

		fetchFiles();
	}, []);

	return (
		<div className="recent">
			<h3>Recent Files</h3>
			<div className="recent__files">
				{files?.length > 0 &&
					files.map((file) => <File key={file.id} file={file} />)}
			</div>
		</div>
	);
}
