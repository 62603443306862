import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { ToastContainer } from "react-toastify";

import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import { PrivateRoute, PublicRoute } from "./RouteHelper";
import { auth } from "./firebase";
import { addUser, removeUser } from "./store/user";
import { createUserDb } from "./service/user";
import Pinned from "./pages/pinned/Pinned";
import Plans from "./pages/plans/Plans";
import Recent from "./pages/recent/Recent";

function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				const userData = await createUserDb();
				userData.name = user.displayName;
				userData.image = user.photoURL;

				dispatch(addUser(userData));
			} else {
				dispatch(removeUser());
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="app">
			<Routes>
				<Route
					path="/login"
					element={
						<PublicRoute>
							<Login />
						</PublicRoute>
					}
				/>
				<Route
					path="/"
					element={
						<PrivateRoute>
							<Home />
						</PrivateRoute>
					}
				/>
				<Route
					path="/pinned"
					element={
						<PrivateRoute>
							<Pinned />
						</PrivateRoute>
					}
				/>
				<Route
					path="/recent"
					element={
						<PrivateRoute>
							<Recent />
						</PrivateRoute>
					}
				/>
				<Route
					path="/plans"
					element={
						<PrivateRoute>
							<Plans />
						</PrivateRoute>
					}
				/>
			</Routes>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</div>
	);
}

export default App;
