import { useSelector } from "react-redux";

import "./Pinned.css";

import File from "../../components/file/File";

export default function Pinned() {
	const pinned = useSelector((state) => state?.files?.pinned);

	return (
		<div className="pinned">
			<h3>Pinned Files</h3>
			<div className="pinned__files">
				{pinned?.length > 0 &&
					pinned.map((file) => <File key={file.id} file={file} />)}
			</div>
		</div>
	);
}
