export default function PinIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Iconly/Light/Bookmark">
				<g id="Bookmark">
					<path
						id="Stroke 1"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M19.7388 6.15362C19.7388 3.40274 17.8581 2.29999 15.1504 2.29999H8.79148C6.16691 2.29999 4.2 3.32756 4.2 5.97016V20.694C4.2 21.4198 4.98095 21.8769 5.61353 21.522L11.9955 17.9421L18.3223 21.516C18.9559 21.8729 19.7388 21.4158 19.7388 20.689V6.15362Z"
						stroke="#212121"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Stroke 3"
						d="M8.27118 9.02799H15.5895"
						stroke="#212121"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
}
