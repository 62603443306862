import {
	collection,
	doc,
	getDoc,
	serverTimestamp,
	setDoc,
	updateDoc,
} from "firebase/firestore";

import { auth, db } from "../firebase";

export async function createUserDb() {
	if (await isAlreadyUser()) return getUserDetails();

	try {
		let userId = auth?.currentUser?.uid;
		await setDoc(
			doc(collection(db, "users"), userId),
			{
				uerId: userId,
				storageUsedBytes: 0,
				payPackageId: "hedgehog_free",
				updatedAt: new Date(),
				createdAt: new Date(),
			},
			{ merge: true }
		);

		return getUserDetails();
	} catch (err) {
		console.error(err);
		return null;
	}
}

async function isAlreadyUser() {
	try {
		let userId = auth?.currentUser?.uid;
		const docRef = doc(db, "users", userId);
		const docSnap = await getDoc(docRef);

		return docSnap.exists();
	} catch (err) {
		console.error(err);
		return false;
	}
}

export async function getUserDetails() {
	try {
		let userId = auth?.currentUser?.uid;
		const docRef = doc(db, "users", userId);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			const data = docSnap.data();
			data.createdAt = new Date(data.createdAt.toMillis());
			data.updatedAt = new Date(data.updatedAt.toMillis());
			return data;
		}

		return null;
	} catch (err) {
		console.error(err);
		return null;
	}
}

export async function updatePlan(package_id, subscription_id, source) {
	try {
		let userId = auth?.currentUser?.uid;
		const docRef = doc(db, "users", userId);

		await updateDoc(
			docRef,
			{
				updatedAt: serverTimestamp(),
				payPackageId: package_id,
				subscriptionId: subscription_id,
				paymentSource: source,
			},
			{ merge: true }
		);
	} catch (err) {
		console.error(err);
	}
}

export async function updateStorage(size) {
	try {
		let userId = auth?.currentUser?.uid;
		const docRef = doc(db, "users", userId);

		await updateDoc(
			docRef,
			{
				updatedAt: serverTimestamp(),
				storageUsedBytes: size,
			},
			{ merge: true }
		);
	} catch (err) {
		console.error(err);
	}
}
