import {
	Navbar,
	Container,
	OverlayTrigger,
	ProgressBar,
	Popover,
} from "react-bootstrap";

import "./Nav.css";

import LogoutIcon from "../../icons/LogoutIcon";
import UserIcon from "../../icons/UserIcon";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../store/user";
import { payPackages } from "../../pages/plans/Plans";

export default function Nav() {
	const user = useSelector((state) => state?.user?.user);
	const dispatch = useDispatch();

	return (
		<Navbar bg="light">
			<Container>
				<h5>Hedgehog Lock</h5>
				<Navbar.Toggle />
				<Navbar.Collapse className="justify-content-end">
					<OverlayTrigger
						trigger="click"
						rootClose={true}
						placement="bottom"
						overlay={
							<Popover className="user__popover">
								<Popover.Header as="h2">
									{user?.name}
								</Popover.Header>
								<Popover.Body>
									<div className="user__popoverListItem">
										<p>Storage</p>
										<ProgressBar
											now={
												user.storageUsedBytes /
												payPackages.find(
													(pkg) =>
														pkg.payId ===
														user.payPackageId
												).storageBytes
											}
										/>
										<span>
											{(
												user.storageUsedBytes /
												(1000 * 1000 * 1000)
											).toFixed(1)}
											{" of "}
											{
												payPackages.find(
													(pkg) =>
														pkg.payId ===
														user.payPackageId
												).storageInGB
											}
											GB used
										</span>
									</div>
									<div
										className="user__popoverListItem"
										onClick={() => dispatch(removeUser())}
									>
										<LogoutIcon />
										<span> Logout</span>
									</div>
								</Popover.Body>
							</Popover>
						}
					>
						<div className="nav__userIcon">
							<UserIcon />
							<span>{user?.name}</span>
						</div>
					</OverlayTrigger>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
