export default function FileIcon() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Iconly/Light/Paper">
				<g id="Paper">
					<path
						id="Stroke 1"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.7378 2.76181H8.0848C6.0048 2.75381 4.2998 4.41181 4.2508 6.49081V17.2038C4.2048 19.3168 5.8798 21.0678 7.9928 21.1148C8.0238 21.1148 8.0538 21.1158 8.0848 21.1148H16.0738C18.1678 21.0298 19.8178 19.2998 19.8028 17.2038V8.03781L14.7378 2.76181Z"
						stroke="#212121"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Stroke 3"
						d="M14.4751 2.75V5.659C14.4751 7.079 15.6231 8.23 17.0431 8.234H19.7981"
						stroke="#212121"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Stroke 5"
						d="M14.2881 15.3585H8.88809"
						stroke="#212121"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Stroke 7"
						d="M12.2432 11.606H8.88721"
						stroke="#212121"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</svg>
	);
}
