import { Button, Form, Modal } from "react-bootstrap";

import "./UploadModal.css";
import { useSelector } from "react-redux";
import { auth } from "../../firebase";
import { useState } from "react";
import { toast } from "react-toastify";
import { upload } from "../../service/files";

export default function UploadModal({ show, setShow }) {
	const folders = useSelector((state) => state?.files?.folders);
	const userId = auth.currentUser.uid;

	const [file, setFile] = useState(null);
	const [folder, setFolder] = useState(null);

	async function handleUpload(e) {
		e?.preventDefault();

		if (!file) {
			toast("Please select a valid file to upload", {
				type: toast.TYPE.ERROR,
			});
		} else {
			let selectedFolder = folder;
			if (!selectedFolder) {
				selectedFolder = userId;
			}
			const response = await upload(file, selectedFolder);

			if (response) {
				toast("Successfully uploaded", {
					type: toast.TYPE.SUCCESS,
				});
				setShow();
				setTimeout(() => window.location.reload(), 900);
			} else {
				toast(
					"Something went wrong while uploading the file, please try again",
					{
						type: toast.TYPE.ERROR,
					}
				);
			}
		}
	}

	return (
		<Modal show={show} onHide={setShow} size="sm" className="upload__modal">
			<Modal.Header closeButton>Upload file</Modal.Header>
			<Modal.Body>
				<div>
					<Form.Label>Select Folder</Form.Label>
					<Form.Select
						aria-label="Select Folder"
						onChange={(e) => setFolder(e.target.value)}
					>
						<option value={userId}>Home</option>
						{folders?.length > 0 &&
							folders.map((folder) => (
								<option key={folder.id} value={folder.id}>
									{folder.folderName}
								</option>
							))}
					</Form.Select>
					<Form.Group controlId="formFileSm" className="mb-3">
						<Form.Control
							type="file"
							onChange={(e) => setFile(e.target.files[0])}
						/>
					</Form.Group>
					<Button type="button" onClick={handleUpload}>
						Upload File
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
