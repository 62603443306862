import { useState, useEffect } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { updatePlan } from "../../service/user";

export default function Checkout({ subscription_id, package_id }) {
	const [success, setSuccess] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (success) {
			toast("Payment successful. You are now subscribed with Hedgehog", {
				type: toast.TYPE.SUCCESS,
			});
			navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [success]);

	return (
		<div className="paypal__checkout">
			<PayPalButtons
				style={{ layout: "vertical", label: "subscribe" }}
				createSubscription={(_data, actions) =>
					actions.subscription.create({
						plan_id: subscription_id,
					})
				}
				onApprove={async (data, _details) => {
					await updatePlan(
						package_id,
						data.subscriptionID,
						data.paymentSource
					);
					setSuccess(true);
				}}
				onError={(err) => {
					toast(
						"Something went wrong with the payment. Please try again",
						{
							type: toast.TYPE.ERROR,
						}
					);
					console.error("An error occured with your payment", err);
				}}
			/>
		</div>
	);
}
