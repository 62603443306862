import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import {
	signInWithRedirect,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-toastify";

import "./Login.css";
import logo from "../../logo.png";
import google from "../../google.png";

import LockFilledIcon from "../../icons/LockFilledIcon";
import EmailFilledIcon from "../../icons/EmailFilledIcon";
import { auth, googleAuth } from "../../firebase";

export default function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	async function handleSubmit(e) {
		e?.preventDefault();

		if (!email || !password) {
			return;
		} else {
			try {
				await signInWithEmailAndPassword(auth, email, password);
			} catch (err) {
				try {
					await createUserWithEmailAndPassword(auth, email, password);
				} catch (err) {
					toast("Please enter valid email and password", {
						type: toast.TYPE.ERROR,
					});
				}
			}
		}
	}

	async function handleGoogleLogin(e) {
		e?.preventDefault();

		try {
			await signInWithRedirect(auth, googleAuth);
		} catch (err) {
			toast(err.toString(), { type: toast.TYPE.ERROR });
			console.error(err);
		}
	}

	return (
		<div className="login">
			<img src={logo} alt="hedgehog logo" />
			<h3>Login to your account</h3>
			<Form onSubmit={handleSubmit}>
				<Form.Group
					className="mb-3 login__input"
					controlId="formBasicEmail"
				>
					<EmailFilledIcon />
					<Form.Control
						type="email"
						placeholder="Enter email"
						required
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Form.Text className="text-muted">
						We'll never share your email with anyone else.
					</Form.Text>
				</Form.Group>
				<Form.Group
					className="mb-3 login__input"
					controlId="formBasicPassword"
				>
					<LockFilledIcon />
					<Form.Control
						type="password"
						placeholder="Password"
						required
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check type="checkbox" label="Remember me" />
				</Form.Group>
				<Button variant="primary" type="submit" onClick={handleSubmit}>
					Login
				</Button>

				<div className="login__bottomForm">
					<small>or continue with</small>
					<Button
						variant="primary"
						type="button"
						onClick={handleGoogleLogin}
					>
						<img src={google} alt="google" />
					</Button>
				</div>
			</Form>
		</div>
	);
}
