import { createSlice } from "@reduxjs/toolkit";

export const filesSlice = createSlice({
	name: "files",
	initialState: {
		files: [],
		pinned: [],
		folders: [],
	},
	reducers: {
		setFiles: (state, action) => ({ ...state, files: action.payload }),
		setFolders: (state, action) => ({ ...state, folders: action.payload }),
		setPinned: (state, action) => ({ ...state, pinned: action.payload }),
		addFile: (state, action) => ({
			...state,
			files: [...state.files, action.payload],
		}),
		addFolder: (state, action) => ({
			...state,
			folders: [...state.folders, action.payload],
		}),
		addPinned: (state, action) => ({
			...state,
			pinned: [...state.pinned, action.payload],
		}),
	},
});

export const {
	setFiles,
	setFolders,
	setPinned,
	addFile,
	addFolder,
	addPinned,
} = filesSlice.actions;

export default filesSlice.reducer;
