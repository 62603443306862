import { Navigate, useLocation } from "react-router-dom";

import Sidebar from "./components/sidebar/Sidebar";
import Nav from "./components/nav/Nav";
import { useSelector } from "react-redux";

export function PrivateRoute({ children }) {
	const user = useSelector((state) => state?.user?.user);
	const location = useLocation();

	return user ? (
		<>
			<Sidebar />
			<main>
				<Nav />
				{children}
			</main>
		</>
	) : (
		<Navigate
			replace={true}
			to="/login"
			state={{ from: `${location.pathname}${location.search}` }}
		/>
	);
}

export function PublicRoute({ children }) {
	const user = useSelector((state) => state?.user?.user);
	const location = useLocation();

	return user ? (
		<Navigate
			replace={true}
			to="/"
			state={{ from: `${location.pathname}${location.search}` }}
		/>
	) : (
		<>{children}</>
	);
}
