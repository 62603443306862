import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import "./Home.css";

import logo from "../../logo.png";
import { getFiles, getFolders } from "../../service/files";
import { setFiles, setFolders, setPinned } from "../../store/files";
import File from "../../components/file/File";
import folder_image from "../../folder.png";
import FolderModal from "../../components/folderModal/FolderModal";
import UploadModal from "../../components/uploadModal/UploadModal";
import UploadIcon from "../../icons/UploadIcon";
import { getFormattedDate } from "../../util/util";

export default function Home() {
	const user = useSelector((state) => state?.user?.user);
	const dispatch = useDispatch();

	const [userFiles, setUserFiles] = useState([]);
	const [userFolders, setUserFolders] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedFolder, setSelectedFolder] = useState(null);
	const [showFileUploadModal, setShowFileUploadModal] = useState(false);

	function toggleShowModal() {
		setShowModal(!showModal);
	}

	function toggleFileUploadModal() {
		setShowFileUploadModal(!showFileUploadModal);
	}

	// Fetch files and folders
	useEffect(() => {
		async function fetchFiles() {
			if (user) {
				const { files, pinned } = await getFiles();
				if (files.length > 0) {
					setUserFiles(files);
					dispatch(setFiles(files));
					if (pinned.length > 0) {
						dispatch(setPinned(pinned));
					}
				}
			}
		}

		async function fetchFolders() {
			if (user) {
				const folders = await getFolders();
				if (folders?.length > 0) {
					setUserFolders(folders);
					dispatch(setFolders(folders));
				}
			}
		}

		fetchFiles();
		fetchFolders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<div className="home">
			{userFiles.length === 0 && userFolders.length === 0 ? (
				<div className="home__welcomeDiv">
					<img src={logo} alt="hedgehog logo" />
					<h2>Welcome to HedgehogLock</h2>
				</div>
			) : (
				<>
					{userFolders.length > 0 && <h3>Folders</h3>}
					<div className="home__files">
						{userFolders.map((folder) => (
							<div
								className="file__container"
								key={folder.id}
								onClick={() => {
									setSelectedFolder(folder);
									toggleShowModal();
								}}
							>
								<img src={folder_image} alt="folder" />
								<p>{folder.folderName}</p>
								<span>
									Created{" "}
									{getFormattedDate(
										new Date(folder.createdAt.toMillis())
									)}
								</span>
							</div>
						))}
					</div>
					{showModal && (
						<FolderModal
							show={showModal}
							setShow={toggleShowModal}
							folderId={selectedFolder?.id}
							name={selectedFolder?.folderName}
						/>
					)}
					{userFiles.length > 0 && <h3>Files</h3>}
					<div className="home__files">
						{userFiles.map((file) => (
							<File key={file.id} file={file} />
						))}
					</div>
				</>
			)}
			<UploadModal
				show={showFileUploadModal}
				setShow={toggleFileUploadModal}
			/>
			<Button
				className="home__uploadButton"
				onClick={toggleFileUploadModal}
			>
				<UploadIcon /> Upload File
			</Button>
		</div>
	);
}
