import {
	addDoc,
	collection,
	doc,
	getDocs,
	orderBy,
	query,
	serverTimestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { getUserDetails, updateStorage } from "./user";
import { payPackages } from "../pages/plans/Plans";
import { toast } from "react-toastify";

export async function getFiles() {
	var files = [];
	var pinned = [];

	try {
		let userId = auth?.currentUser?.uid;
		const filesRef = collection(db, "users", userId, "files");
		const fileDocs = await getDocs(
			query(
				filesRef,
				where("isDeleted", "==", false),
				orderBy("updatedAt", "desc")
			)
		);

		fileDocs.forEach((document) => {
			const doc = { id: document.id, ...document.data() };
			files.push(doc);

			if (doc.isPinned ?? false) {
				pinned.push(doc);
			}
		});
	} catch (err) {
		console.error(err);
	}

	return { files, pinned };
}

export async function getFolders() {
	var folders = [];

	try {
		let userId = auth?.currentUser?.uid;
		const foldersRef = collection(db, "users", userId, "folders");
		const folderDocs = await getDocs(
			query(
				foldersRef,
				where("isDeleted", "==", false),
				orderBy("updatedAt", "desc")
			)
		);

		folderDocs.forEach((document) => {
			const doc = { id: document.id, ...document.data() };
			folders.push(doc);
		});
	} catch (err) {
		console.error(err);
	}

	return folders;
}

export function getFileSearchList(fileList, searchKey) {
	const matchedFiles = [];

	try {
		for (let fileItem in fileList) {
			if (fileItem?.fileName.toLowerCase().contains(searchKey)) {
				matchedFiles.push(fileItem);
			}
		}
	} catch (err) {
		console.error(err);
	}

	return matchedFiles;
}

export function getFolderSearchList(folderList, searchKey) {
	const matchedFolders = [];

	try {
		for (let folderItem in folderList) {
			if (folderItem?.folderName.toLowerCase().contains(searchKey)) {
				matchedFolders.push(folderItem);
			}
		}
	} catch (err) {
		console.error(err);
	}

	return matchedFolders;
}

export async function updateFileRecentInfo(fileId) {
	try {
		let userId = auth?.currentUser?.uid;
		const fileRef = doc(db, "users", userId, "files", fileId);
		await updateDoc(
			fileRef,
			{ lastUsed: serverTimestamp() },
			{ merge: true }
		);
	} catch (err) {
		console.error(err);
	}
}

export async function getRecentFiles() {
	var files = [];

	try {
		let userId = auth?.currentUser?.uid;
		const filesRef = collection(db, "users", userId, "files");
		const fileDocs = await getDocs(
			query(
				filesRef,
				where("isDeleted", "==", false),
				orderBy("lastUsed", "desc")
			)
		);

		fileDocs.forEach((document) => {
			const doc = { id: document.id, ...document.data() };
			files.push(doc);
		});
	} catch (err) {
		console.error(err);
	}

	return files;
}

export async function upload(file, parentId) {
	try {
		const formData = new FormData();
		formData.append("file", file, file.name);

		if (isUploadPossible(file.size)) {
			const data = await uploadToIpfs(formData);

			let userId = auth?.currentUser?.uid;
			const filesRef = collection(db, "users", userId, "files");

			if (data) {
				await addDoc(filesRef, {
					cId: data.hash.path,
					isDeleted: false,
					parentId: parentId,
					file: "",
					fileId: null,
					fileName: file.name,
					fileType: file.type.split("/")[1],
					fileUrl: `https://ipfs.io/ipfs/${data.hash.path}`,
					fileSize: data.hash.size,
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
					isPinned: false,
					password: null,
				});
			}

			return true;
		} else {
			toast("Please upgrade your package", { type: toast.TYPE.ERROR });
			return false;
		}
	} catch (err) {
		console.error(err);
		return false;
	}
}

async function uploadToIpfs(formData) {
	try {
		const response = await fetch("https://api.hedgehoglock.com/upload", {
			method: "POST",
			body: formData,
		});
		const data = await response.json();

		return data;
	} catch (err) {
		console.error(err);
	}

	return null;
}

async function isUploadPossible(size) {
	const user = await getUserDetails();
	const planSize = payPackages.find(
		(pkg) => pkg.payId === user.payPackageId
	).storageBytes;

	if (user.storageUsedBytes + size <= planSize) {
		await updateStorage(user.storageUsedBytes + size);
		return true;
	} else {
		return false;
	}
}
