import { Sidebar as SideBar, Menu, MenuItem } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

import "./Sidebar.css";
import logo from "../..//logo.png";

import RecentIcon from "../../icons/RecentIcon";
import HomeIcon from "../../icons/HomeIcon";
import PinIcon from "../../icons/PinIcon";
import FileIcon from "../../icons/FileIcon";

export default function Sidebar() {
	const navigate = useNavigate();

	return (
		<SideBar>
			<div className="app__sidebarMenu">
				<div className="app__sidebarMenuTop">
					<img src={logo} alt="hedgehog logo" />
					<Menu>
						<MenuItem onClick={() => navigate("/")}>
							<HomeIcon />
							<span>Home</span>
						</MenuItem>
						<MenuItem onClick={() => navigate("/recent")}>
							<RecentIcon />
							<span>Recent</span>
						</MenuItem>
						<MenuItem onClick={() => navigate("/pinned")}>
							<PinIcon />
							<span>Pinned</span>
						</MenuItem>
					</Menu>
				</div>
				<div className="app__sidebarMenuBottom">
					<Menu>
						<MenuItem onClick={() => navigate("/plans")}>
							<FileIcon />
							<span>Membership</span>
						</MenuItem>
					</Menu>
				</div>
			</div>
		</SideBar>
	);
}
