import { Button } from "react-bootstrap";

import "./Plans.css";

import basic from "../../basic.png";
import free from "../../free.png";
import pro from "../../pro.png";
import singular from "../../singular.png";
import Checkout from "../../components/checkout/Checkout";
import { useState } from "react";
import { useSelector } from "react-redux";

export const payPackages = [
	{
		price: 2.95,
		storageBytes: 500 * 1000 * 1000 * 1000,
		storageInGB: 500,
		payId: "hedgehog_basic",
		name: "Hedgehog Basic",
		paypalId: process.env.REACT_APP_BASIC_PLAN,
		bandwidth: 80,
		devices: 3,
	},
	{
		price: 4.95,
		storageBytes: 1000 * 1000 * 1000 * 1000,
		storageInGB: 1000,
		payId: "hedgehog_pro",
		name: "Hedgehog Pro",
		paypalId: process.env.REACT_APP_PRO_PLAN,
		bandwidth: 900,
		devices: 15,
	},
	{
		price: 9.95,
		storageBytes: 3000 * 1000 * 1000 * 1000,
		storageInGB: 3000,
		payId: "hedgehog_singular",
		name: "Hedgehog Singular",
		paypalId: process.env.REACT_APP_SINGULAR_PLAN,
		bandwidth: 2500,
		devices: 30,
	},
	{
		price: 0,
		storageBytes: 15 * 1000 * 1000 * 1000,
		storageInGB: 15,
		payId: "hedgehog_free",
		name: "Hedgehog Free",
		bandwidth: 1,
		devices: 1,
	},
];

export default function Plans() {
	const user = useSelector((state) => state?.user?.user);

	const [show, setShow] = useState(false);
	const [selected, setSelected] = useState(null);

	function handleClick(id) {
		setSelected(payPackages[id]);
		setShow(true);
	}

	return (
		<div className="plans">
			{show && (
				<Checkout
					subscription_id={selected?.paypalId}
					package_id={selected?.payId}
				/>
			)}
			<div className="plans__container">
				<div
					className={
						user?.payPackageId === payPackages[0].payId
							? "plan active"
							: "plan"
					}
				>
					<img src={basic} alt="basic_plan" />
					<h3>
						Hedgehog <span>Basic</span>
					</h3>
					<p>
						<b>$ {payPackages[0].price}</b> / Month
					</p>
					<div>
						<li>500 GB Storage</li>
						<li>Unlimited pinning</li>
						<li>Encyption access</li>
					</div>
					<Button type="button" onClick={() => handleClick(0)}>
						Choose option
					</Button>
				</div>

				<div
					className={
						user?.payPackageId === payPackages[1].payId
							? "plan active"
							: "plan"
					}
				>
					<img src={pro} alt="pro_plan" />
					<h3>
						Hedgehog <span>Pro</span>
					</h3>
					<p>
						<b>$ {payPackages[1].price}</b> / Month
					</p>
					<div>
						<li>1000 GB Storage</li>
						<li>Unlimited pinning</li>
						<li>Encyption access</li>
					</div>
					<Button type="button" onClick={() => handleClick(1)}>
						Choose option
					</Button>
				</div>

				<div
					className={
						user?.payPackageId === payPackages[2].payId
							? "plan active"
							: "plan"
					}
				>
					<img src={singular} alt="singular_plan" />
					<h3>
						Hedgehog <span>Singular</span>
					</h3>
					<p>
						<b>$ {payPackages[2].price}</b> / Month
					</p>
					<div>
						<li>3000 GB Storage</li>
						<li>Unlimited pinning</li>
						<li>Encyption access</li>
					</div>
					<Button type="button" onClick={() => handleClick(2)}>
						Choose option
					</Button>
				</div>

				<div
					className={
						user?.payPackageId === payPackages[3].payId
							? "plan active"
							: "plan"
					}
				>
					<img src={free} alt="free_plan" />
					<h3>
						Hedgehog <span>Free</span>
					</h3>
					<p>
						<b>$ {payPackages[3].price}</b> / Month
					</p>
					<div>
						<li>15 GB Storage</li>
						<li>Unlimited pinning</li>
						<li>Encyption access</li>
					</div>
					<Button type="button" onClick={() => handleClick(3)}>
						Choose option
					</Button>
				</div>
			</div>
		</div>
	);
}
