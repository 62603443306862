import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import "./FolderModal.css";

import File from "../file/File";
import folder_image from "../../folder.png";

export default function FolderModal({ show, setShow, folderId, name }) {
	const files = useSelector((state) => state?.files?.files);
	const [userFiles, setUserFiles] = useState([]);

	useEffect(() => {
		if (files?.length > 0) {
			const filtered = files.filter((file) => file.parentId === folderId);
			setUserFiles(filtered);
		}
	}, [files, folderId]);

	return (
		<Modal show={show} onHide={setShow} size="lg" className="folder__modal">
			<Modal.Header closeButton>
				<img src={folder_image} alt="folder" /> {name}
			</Modal.Header>
			<Modal.Body>
				<div>
					{userFiles.map((file) => (
						<File key={file.id} file={file} />
					))}
				</div>
			</Modal.Body>
		</Modal>
	);
}
